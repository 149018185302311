import request from '@/plugins/axios'

// 首页头部数据
export function statistics() {
  return request({
    url: '/statistics',
    method: 'get'
  })
}
// 近7天
export function collectionAmount() {
  return request({
    url: '/statistics/collectionAmount',
    method: 'get'
  })
}

// export function siteConfig(page, limit) {
//   return request({
//     url: `/web/site/config/${page}/${limit}`,
//     method: 'post'
//   })
// }

// export function logRead() {
//   return request({
//     url: `/log/read`,
//     method: 'get'
//   })
// }
