<template>
  <div class="youtube-index">
    <div class="youtube-head">
      <div class="head-item" v-for="(item, i) in headArr" :key="i">
        <img :src="item.img" alt="" />
        <div class="text-box">
          <div v-if="!item.bold" class="item-text">
            {{ item.memo1 }}
          </div>
          <div v-else class="item-text">
            <span>{{ item.memo1 }}</span>
            {{ item.measurement }}
          </div>
          <div class="item-text">
            {{ item.memo2 }}
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="youtube-content">
      <div>
        <div class="index-title" style="border:none">近7天检索统计</div>
        <div id="echarts"></div>
      </div>
      <div>
        <div class="index-title">
          <div>我的频道</div>
          <span @click="goPath('/key/channel')">更多>></span>
        </div>
        <el-table
          :data="communityList"
          header-row-class-name="tableheader"
          style="width: 100%; margin-top: 10px"
          height="280px"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '5px 0' }"
        >
          <el-table-column
            label="链接地址"
            prop="channelUrl"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <a :href="scope.row.channelUrl" target="_blank">
                {{ scope.row.channelUrl }}
              </a>
            </template>
          </el-table-column>
          <el-table-column
            prop="title"
            label="名称"
            width="200"
            show-overflow-tooltip
          ></el-table-column>

          <el-table-column
            prop="joinedDate"
            label="创建时间"
            width="180"
          ></el-table-column>
        </el-table>
      </div>
      <!-- -->
    </div>
    <div class="youtube-foot">
      <div class="foot-left">
        <div class="index-title">
          <div>最新视频数据</div>
          <span @click="goPath('/retrieval/video')">更多>></span>
        </div>
        <el-table
          :data="communityArr"
          header-row-class-name="tableheader"
          style="width: 100%; margin-top: 10px"
          :height="windowsHeight - 625"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '5px 0' }"
          :show-header="false"
        >
          <el-table-column prop="title" label="标题">
            <template slot-scope="scope">
              <div class="new-video">
                <div class="new-video-img">
                  <img :src="scope.row.videoThumbnail" alt="" />
                </div>
                <div class="new-video-content">
                  <div class="video-title">
                    {{ scope.row.title }}
                  </div>
                  <div class="video-info">
                    <span>{{ scope.row.author }}</span>
                    <span style="margin-left:15px">
                      {{ scope.row.viewCount }}次观看
                    </span>
                    <span style="font-size:26px; vertical-align: middle;">
                      ·
                    </span>
                    <span>{{ scope.row.publishDate }}</span>
                  </div>
                </div>
              </div>
              <div class="new-video-a">
                <a :href="scope.row.url" target="_blank">
                  来源：{{ scope.row.url }}
                </a>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="foot-center">
        <div class="index-title">
          <div>评论数据</div>
          <span @click="goPath('/retrieval/comment')">更多>></span>
        </div>
        <el-table
          :show-header="false"
          :data="userList"
          header-row-class-name="tableheader"
          style="width: 100%; margin-top: 10px"
          :height="windowsHeight - 625"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '5px 0' }"
        >
          <el-table-column label="">
            <template slot-scope="scope">
              <div class="new-video">
                <div class="new-video-img">
                  <img :src="scope.row.authorThumbnail" alt="" />
                </div>
                <div class="new-video-content">
                  <div class="video-title">
                    <div>
                      <span>{{ scope.row.author }}</span>
                      <span style="font-size:26px; vertical-align: middle;">
                        ·
                      </span>
                      <span>{{ scope.row.publishDate }}</span>
                    </div>
                    <div class="video-title-right">
                      <a :href="scope.row.authorUrl" target="_blank">
                        {{ scope.row.authorUrl }}
                      </a>
                    </div>
                  </div>
                  <div class="video-info">
                    <span>{{ scope.row.contentText }}</span>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="foot-right">
        <div class="index-title">
          <div>我的关键词</div>
          <span @click="goPath('/key/words')">更多>></span>
        </div>
        <el-table
          :data="accountList"
          header-row-class-name="tableheader"
          :show-header="false"
          style="width: 100%; margin-top: 10px"
          :height="windowsHeight - 625"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '5.5px 0' }"
        >
          <el-table-column
            prop="groupName"
            label=""
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { statistics, collectionAmount } from '@/api/dashboard.js'
import { channelPage, videoList, commentPage } from '@/api/channel.js'
import { keyWordsPage } from '@/api/keywords.js'
export default {
  data() {
    return {
      noPic: 'this.src="' + require('@/assets/imgs/i12.png') + '"',
      headArr: [
        {
          img: require('../../assets/imgs/t1.png'),
          memo1: '开通日期：2020-03-01',
          memo2: '结束日期：2020-03-01',
          bold: false
        },
        {
          img: require('../../assets/imgs/t2.png'),
          memo1: 0,
          memo2: '频道方案',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/t3.png'),
          memo1: 0,
          memo2: '关键词方案',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/t4.png'),
          memo1: 0,
          memo2: '视频数据',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/t5.png'),
          memo1: '0',
          memo2: '评论数据',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/t6.png'),
          memo1: '0',
          memo2: '已收藏',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/t7.png'),
          memo1: '0',
          memo2: '频道数据',
          bold: true,
          measurement: ''
        }
      ],
      communityList: [],
      communityArr: [],
      accountList: [],
      userList: []
    }
  },
  computed: {
    windowsHeight() {
      return this.$store.state.windowData.height
    },
    info() {
      return this.$store.state.user.userInfo
    }
  },
  mounted() {
    // 获取头部6个数据
    this.getHead()
    // 获取折线图的数据
    this.getEcharts()
    // 我的频道
    this.getChannelPage()
    // 视频数据
    this.getVideoList()
    // 评论数据
    this.getCommentPage()
    //关键词
    this.getKey()
    let time = this.info.createTime ? this.info.createTime : ''

    this.headArr[0].memo1 = '开通日期：' + time
    this.headArr[0].memo2 = '结束日期：' + this.info.endDate
  },
  methods: {
    // 频道数据
    getChannelPage() {
      channelPage(1, 7, {}).then(data => {
        this.communityList = data.items.map(item => {
          if (item.channel) {
            return item.channel
          } else {
            return {
              joinedDate: '未知',
              title: '未知',
              channelUrl: '未知'
            }
          }
        })
      })
    },
    // 视频数据
    getVideoList() {
      let obj = {}
      videoList(1, 20, obj).then(res => {
        this.communityArr = res.items
      })
    },
    // 评论数据
    getCommentPage() {
      commentPage(1, 10, {}).then(res => {
        this.userList = res.items
      })
    },
    // 关键词数据
    getKey() {
      keyWordsPage(1, 10).then(data => {
        this.accountList = data.items
      })
    },
    goPath(path) {
      this.$router.push(path)
    },

    getHead() {
      statistics().then(res => {
        // this.headArr[0].memo1 =
        //   '开通日期：' + this.$store.state.user.userInfo.create_time
        // this.headArr[0].memo2 =
        //   '结束日期：' + this.$store.state.user.userInfo.endTime
        this.headArr[1].memo1 = res.channelPlanCount
        this.headArr[2].memo1 = res.keyPlanCount
        this.headArr[3].memo1 = res.videoCount
        this.headArr[4].memo1 = res.commentCount
        this.headArr[5].memo1 = res.collectCount
        this.headArr[6].memo1 = res.channelCount
      })
    },
    getEcharts() {
      collectionAmount().then(res => {
        this.getEchartsLine(
          'echarts',
          res.map(i => {
            return i.date
          }),
          res.map(i => {
            return parseFloat(i.videoCount)
          }),
          res.map(i => {
            return parseFloat(i.commentsCount)
          })
        )
      })
    },
    getEchartsLine(dom, dataX, dataY1, dataY2) {
      const chart = echarts.init(document.getElementById(dom))
      const option = {
        grid: {
          x: 50,
          y: 40,
          x2: 30,
          y2: 20,
          borderWidth: 10
        },
        xAxis: {
          type: 'category',
          data: dataX,
          // boundaryGap: false
          splitLine: { show: true }
        },
        yAxis: {
          type: 'value',
          splitLine: { show: true }
        },
        legend: {},
        tooltip: {
          // 提示框组件
          trigger: 'item', // 触发类型,'item'数据项图形触发，主要在散点图，饼图等无类目轴的图表中使用。 'axis'坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用。
          triggerOn: 'mousemove', // 提示框触发的条件,'mousemove'鼠标移动时触发。'click'鼠标点击时触发。'mousemove|click'同时鼠标移动和点击时触发。'none'不在 'mousemove' 或 'click' 时触发
          showContent: true, // 是否显示提示框浮层
          alwaysShowContent: false, // 是否永远显示提示框内容
          showDelay: 0, // 浮层显示的延迟，单位为 ms
          hideDelay: 100, // 浮层隐藏的延迟，单位为 ms
          enterable: false, // 鼠标是否可进入提示框浮层中
          confine: false, // 是否将 tooltip 框限制在图表的区域内
          transitionDuration: 0.4, // 提示框浮层的移动动画过渡时间，单位是 s,设置为 0 的时候会紧跟着鼠标移动
          // position: ["50%", "50%"], //提示框浮层的位置，默认不设置时位置会跟随鼠标的位置,[10, 10],回掉函数，inside鼠标所在图形的内部中心位置，top、left、bottom、right鼠标所在图形上侧，左侧，下侧，右侧，
          // formatter: "{b0}: {c0}<br />{b1}: {c1}", //提示框浮层内容格式器，支持字符串模板和回调函数两种形式,模板变量有 {a}, {b}，{c}，{d}，{e}，分别表示系列名，数据名，数据值等
          backgroundColor: 'transparent', // 标题背景色
          borderColor: '#ccc', // 边框颜色
          borderWidth: 0, // 边框线宽
          padding: 5 // 图例内边距，单位px  5  [5, 10]  [5,10,5,10]
        },

        series: [
          {
            data: dataY1,
            type: 'bar',
            color: '#FCCB22',
            barWidth: 21,
            name: '视频数据'
          },
          {
            data: dataY2,
            type: 'bar',
            color: '#A4C9F9',
            barWidth: 21,
            name: '评论数据'
          }
        ]
      }
      window.onresize = function() {
        chart.resize()
      }
      chart.setOption(option)
    }
  }
}
</script>

<style lang="scss" scoped>
.youtube-index {
  width: 100%;
  height: calc(100vh - 50px);
  padding: 10px 20px;
  background: rgb(240, 242, 245);

  .youtube-head {
    width: 100%;
    height: 141px;
    background: white;
    border-radius: 5px;
    display: flex;
    box-shadow: 0 1px 3px 0 #d8d9d9;
    .head-item {
      width: calc(100% / 7 - 1px);
      height: 131px;
      border-right: 1px solid #f2f4f8;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      &:nth-last-child(1) {
        border-right: none;
      }
      img {
        width: 28px;
        height: auto;
        object-fit: cover;
        align-self: center;
      }
      .text-box {
        align-self: center;
        margin-top: 20px;
        text-align: center;
        .item-text {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          span {
            font-size: 22px;
          }
        }
      }
    }
  }
  .youtube-content {
    height: 337px;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    > div {
      width: 49.7%;
      height: 100%;
      background: #ffffff;
      border-radius: 5px;
      padding: 5px;
      overflow: hidden;
      box-shadow: 0 1px 3px 0 #d8d9d9;
    }

    #echarts {
      width: 100%;
      height: calc(100% - 40px);
    }
  }
  .youtube-foot {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;

    > div {
      background: white;
      border-radius: 5px;
      height: 100%;
      padding: 5px;
      box-shadow: 0 1px 3px 0 #d8d9d9;
    }
    .foot-left {
      width: calc((100% - 277px) / 2 - 10px);
      .new-video {
        display: flex;
        .new-video-img {
          width: 71px;
          height: 40px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .new-video-content {
          margin-left: 15px;
          width: calc(100% - 90px);
          .video-title {
            font-size: 14px;
            font-weight: 300;
            color: #1a1a1b;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .video-info {
            font-size: 12px;
            font-weight: 300;
            color: #444444;
          }
        }
      }
      .new-video-a {
        a {
          font-size: 12px;
          font-weight: 300;
          color: #1a1a1b;
        }
      }
    }
    .foot-center {
      width: calc((100% - 277px) / 2 - 10px);
      .new-video {
        display: flex;
        .new-video-img {
          width: 40px;
          height: 40px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        .new-video-content {
          margin-left: 15px;
          width: calc(100% - 60px);
          .video-title {
            font-size: 12px;
            font-weight: 400;
            color: #1a1a1b;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: flex;
            justify-content: space-between;
            .video-title-right {
              // background: #1a1a1b;
              // width: calc(100% - 200px);
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              margin-left: 20px;
            }
          }
          .video-info {
            font-size: 13px;
            font-weight: 300;
            color: #030303;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .foot-right {
      width: 277px;
    }
  }
  .index-title {
    display: flex;
    justify-content: space-between;
    text-indent: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #4a4a4a;
    line-height: 24px;
    border-bottom: 1px solid #f4f4f4;
    padding: 5px 0;
    font-weight: 600;
    span {
      color: #7c7b7b;
      cursor: pointer;
      &:hover {
        color: rgb(11, 106, 214);
      }
    }
  }
  // ::v-deep .el-table__header .el-table__cell {
  //   background: rgb(243, 247, 249);
  // }
  ::v-deep .tableheader th {
    background: rgb(243, 247, 249);
    padding: 8px 0;
  }
  .table-cell-img {
    width: 28px;
    height: 28px;
    object-fit: cover;
    vertical-align: middle;
  }
}
.index-range-logo {
  width: 29px;
  height: 29px;
  object-fit: cover;
  vertical-align: middle;
}
</style>
